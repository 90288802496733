import EmblaCarousel from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import "bootstrap/dist/js/bootstrap.min";
import "velocity-animate";
import {
  addThumbBtnsClickHandlers,
  addToggleThumbBtnsActive,
} from "./components/EmblaCarouselThumbsButton";
import { addPrevNextBtnsClickHandlers } from "./components/EmblaCarouselArrowButton";
import { setupProgressBar } from "./components/EmblaCarouselProgressBar";
import { addDotBtnsAndClickHandlers } from "./components/EmblaCarouselDotButton";
import { Lightbox } from "./components/lightbox";
import "./lib/bootstrap-filestyle.min";
import "./lib/jquery.scrollbox.min";
import "./lib/parentTheme";

const headerTop = document.querySelector("#header");

if (headerTop) {
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;

    if (scrollY > 25) {
      headerTop.classList.add("fixed");
    } else {
      headerTop.classList.remove("fixed");
    }
  });
}

const viewportNodeMainCarousel = document.querySelector(".embla__viewport");
const viewportNodeThumbCarousel = document.querySelector(
  ".embla-thumbs__viewport"
);

const handleQuickViewEmbla = (productModal) => {
  const emblaNode = productModal.querySelector(".embla__viewport");
  const emblaThumbCarousel = productModal.querySelector(
    ".embla-thumbs__viewport"
  );

  if (emblaNode && emblaThumbCarousel) {
    const prevBtnNode = emblaNode.querySelector(".embla__button--prev");
    const nextBtnNode = emblaNode.querySelector(".embla__button--next");

    const OPTIONS = { align: "start", loop: true };
    const OPTIONS_THUMBS = {
      containScroll: "keepSnaps",
      dragFree: true,
      loop: true,
    };

    const emblaApiMain = EmblaCarousel(emblaNode, OPTIONS);
    const emblaApiThumb = EmblaCarousel(emblaThumbCarousel, OPTIONS_THUMBS);

    const removeThumbBtnsClickHandlers = addThumbBtnsClickHandlers(
      emblaApiMain,
      emblaApiThumb
    );
    const removeToggleThumbBtnsActive = addToggleThumbBtnsActive(
      emblaApiMain,
      emblaApiThumb
    );
    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      emblaApiMain,
      prevBtnNode,
      nextBtnNode
    );

    emblaApiMain
      .on("destroy", removeThumbBtnsClickHandlers)
      .on("destroy", removePrevNextBtnsClickHandlers);

    emblaApiThumb
      .on("destroy", removeThumbBtnsClickHandlers)
      .on("destroy", removeToggleThumbBtnsActive);
  }
};

// const mobileAccount = document.querySelector(
//   ".mobile-wrapper #_desktop_user_infoo"
// );

// const mobileCart = document.querySelector(
//   ".mobile-wrapper #_desktop_cart__custom"
// );

// const mobileContact = document.querySelector(
//   ".mobile-wrapper #_desktop_contact_link__custom"
// );

// if (mobileAccount) {
//   const icon = mobileAccount.querySelector("span.btn-toggle-mobile");
//   const link = mobileAccount.querySelector("a");

//   icon.addEventListener("click", () => {
//     link.click();
//   });
// }

// if (mobileCart) {
//   const icon = mobileCart.querySelector("label.cart-header");
//   const link = mobileCart.querySelector("a.cart-wishlist-checkout");

//   icon.addEventListener("click", () => {
//     link.click();
//   });
// }

// if (mobileContact) {
//   const icon = mobileContact.querySelector("span.btn-toggle-mobile");
//   const link = mobileContact.querySelector("a");

//   icon.addEventListener("click", () => {
//     link.click();
//   });
// }

if (viewportNodeMainCarousel && viewportNodeThumbCarousel) {
  const prevBtnNode = viewportNodeMainCarousel.querySelector(
    ".embla__button--prev"
  );
  const nextBtnNode = viewportNodeMainCarousel.querySelector(
    ".embla__button--next"
  );

  const OPTIONS = { align: "start", loop: true };
  const OPTIONS_THUMBS = {
    containScroll: "keepSnaps",
    dragFree: true,
    loop: true,
  };

  const emblaApiMain = EmblaCarousel(viewportNodeMainCarousel, OPTIONS);
  const emblaApiThumb = EmblaCarousel(
    viewportNodeThumbCarousel,
    OPTIONS_THUMBS
  );

  const removeThumbBtnsClickHandlers = addThumbBtnsClickHandlers(
    emblaApiMain,
    emblaApiThumb
  );
  const removeToggleThumbBtnsActive = addToggleThumbBtnsActive(
    emblaApiMain,
    emblaApiThumb
  );
  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApiMain,
    prevBtnNode,
    nextBtnNode
  );

  emblaApiMain
    .on("destroy", removeThumbBtnsClickHandlers)
    .on("destroy", removePrevNextBtnsClickHandlers);

  emblaApiThumb
    .on("destroy", removeThumbBtnsClickHandlers)
    .on("destroy", removeToggleThumbBtnsActive);
}

const menuButton = document.querySelector("#_desktop_top_menu");

if (menuButton) {
  const menu = menuButton.querySelector(
    `#${menuButton.id} > .main-menu--wrapper`
  );

  if (menu) {
    menuButton.addEventListener("click", (e) => {
      e.stopPropagation();
      menu.classList.toggle("show");
      const nodeList = menu.querySelectorAll(
        "#top-menu .category, #top-menu .manufacturers"
      );

      nodeList.forEach((item) => {
        item.querySelector(".dropdown-item.with-ul")?.classList.remove("show");
        item.querySelector(".arrowDecoration")?.classList.remove("open");
        item.querySelector(".submenu")?.classList.remove("show");
      });
    });
  }
}

// const quantityInputNode = document.querySelector("#quantity_wanted");
// const quantityMinusNode = document.querySelector(".minus");
// const quantityPlusNode = document.querySelector(".plus");

// if (quantityInputNode && quantityMinusNode && quantityPlusNode) {
//   quantityMinusNode.addEventListener("click", () => {
//     quantityInputNode.value = parseInt(quantityInputNode.value) - 1;
//   });

//   quantityPlusNode.addEventListener("click", () => {
//     quantityInputNode.value = parseInt(quantityInputNode.value) + 1;
//   });
// }

const emblaNode = document.querySelector(".embla.products_list");
const emblaAccessoriesNode = document.querySelector(".embla.accessories");

if (emblaAccessoriesNode) {
  const viewportNode = emblaAccessoriesNode.querySelector(".embla__viewport");
  const prevBtn = emblaAccessoriesNode.querySelector(".embla__button--prev");
  const nextBtn = emblaAccessoriesNode.querySelector(".embla__button--next");
  const progressNode = emblaAccessoriesNode.querySelector(
    ".embla__progress__bar"
  );
  const OPTIONS = { dragFree: true, align: "start" };

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS);
  const { applyProgress, removeProgress } = setupProgressBar(
    emblaApi,
    progressNode
  );

  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApi,
    prevBtn,
    nextBtn
  );

  emblaApi
    .on("init", applyProgress)
    .on("reInit", applyProgress)
    .on("scroll", applyProgress)
    .on("slideFocus", applyProgress)
    .on("destroy", removeProgress)
    .on("destroy", removePrevNextBtnsClickHandlers);
}

if (emblaNode) {
  const viewportNode = emblaNode.querySelector(".embla__viewport");
  const prevBtn = emblaNode.querySelector(".embla__button--prev");
  const nextBtn = emblaNode.querySelector(".embla__button--next");
  const progressNode = emblaNode.querySelector(".embla__progress__bar");
  const OPTIONS = { dragFree: true, align: "start" };

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS);
  const { applyProgress, removeProgress } = setupProgressBar(
    emblaApi,
    progressNode
  );

  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApi,
    prevBtn,
    nextBtn
  );

  emblaApi
    .on("init", applyProgress)
    .on("reInit", applyProgress)
    .on("scroll", applyProgress)
    .on("slideFocus", applyProgress)
    .on("destroy", removeProgress)
    .on("destroy", removePrevNextBtnsClickHandlers);
}

const htmlbannersNode = document.querySelector(".htmlbanners9.embla");

if (htmlbannersNode) {
  const viewportNode = htmlbannersNode.querySelector(".embla__viewport");
  const dotsNode = htmlbannersNode.querySelector(".embla__dots");
  let dependencies = [];

  const autoPlay = htmlbannersNode.getAttribute("data-autoplay");
  if (autoPlay === "true") {
    const speed = Number(htmlbannersNode.getAttribute("data-speed"));
    const autoplay = Autoplay({
      playOnInit: true,
      delay: speed,
      stopOnFocusIn: false,
      stopOnInteraction: false,
      stopOnMouseEnter: false,
      stopOnLastSnap: false,
    });
    dependencies.push(autoplay);
  }

  const OPTIONS = { dragFree: false, align: "start", loop: true };

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS, dependencies);

  const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
    emblaApi,
    dotsNode
  );

  emblaApi.on("destroy", removeDotBtnsAndClickHandlers);
}

const productsGridNodes = document.querySelectorAll(".productsgrid.embla");

productsGridNodes.forEach((productsGridNode) => {
  if (productsGridNode) {
    const viewportNode = productsGridNode.querySelector(".embla__viewport");
    const prevBtn = productsGridNode.querySelector(".embla__button--prev");
    const nextBtn = productsGridNode.querySelector(".embla__button--next");
    const progressNode = productsGridNode.querySelector(
      ".embla__progress__bar"
    );
    const OPTIONS = { dragFree: true, align: "start" };

    const emblaApi = EmblaCarousel(viewportNode, OPTIONS);
    const { applyProgress, removeProgress } = setupProgressBar(
      emblaApi,
      progressNode
    );

    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      emblaApi,
      prevBtn,
      nextBtn
    );

    emblaApi
      .on("init", applyProgress)
      .on("reInit", applyProgress)
      .on("scroll", applyProgress)
      .on("slideFocus", applyProgress)
      .on("destroy", removeProgress)
      .on("destroy", removePrevNextBtnsClickHandlers);
  }
});

const productsTabsSection = document.querySelector(
  ".productstabs-section__inner"
);

if (productsTabsSection) {
  const tabsLink = productsTabsSection.querySelectorAll("a.nav-link");
  const tabsPane = productsTabsSection.querySelectorAll(".tab-pane");

  tabsPane.forEach((pane) => pane.classList.add("fade"));
  tabsLink[0].classList.add("active");
  tabsPane[0].classList.remove("fade");

  tabsLink.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      e.preventDefault();
      const target = tab.getAttribute("href");
      tabsLink.forEach((tab) => {
        tab.classList.remove("active");
      });
      tab.classList.add("active");

      tabsPane.forEach((pane) => {
        const REGEX = new RegExp(`#${pane.id}`);

        if (REGEX.test(target)) {
          pane.classList.remove("fade");
        } else {
          pane.classList.add("fade");
        }
      });
    });
  });
}

const htmlbanners2Node = document.querySelector("#htmlbanners2 .embla");

if (htmlbanners2Node) {
  const viewportNode = htmlbanners2Node.querySelector(".embla__viewport");
  const prevBtn = htmlbanners2Node.querySelector(".embla__button--prev");
  const nextBtn = htmlbanners2Node.querySelector(".embla__button--next");
  const progressNode = htmlbanners2Node.querySelector(".embla__progress__bar");
  const OPTIONS = { dragFree: true, align: "start" };

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS);
  const { applyProgress, removeProgress } = setupProgressBar(
    emblaApi,
    progressNode
  );

  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApi,
    prevBtn,
    nextBtn
  );

  emblaApi
    .on("init", applyProgress)
    .on("reInit", applyProgress)
    .on("scroll", applyProgress)
    .on("slideFocus", applyProgress)
    .on("destroy", removeProgress)
    .on("destroy", removePrevNextBtnsClickHandlers);
}

const blockManufactureriNode = document.querySelector(
  "#manufacturers-home .manufacturers-list.embla"
);

if (blockManufactureriNode) {
  const viewportNode = blockManufactureriNode.querySelector(".embla__viewport");
  const prevBtn = blockManufactureriNode.querySelector(".embla__button--prev");
  const nextBtn = blockManufactureriNode.querySelector(".embla__button--next");
  const OPTIONS = { dragFree: true, align: "start", loop: true };

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS);

  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApi,
    prevBtn,
    nextBtn
  );

  emblaApi.on("destroy", removePrevNextBtnsClickHandlers);
}

const htmlBanner5Node = document.querySelector(
  "#htmlbanners5 .htmlbanners5-inner.embla"
);

if (htmlBanner5Node) {
  const viewportNode = htmlBanner5Node.querySelector(".embla__viewport");
  const prevBtn = htmlBanner5Node.querySelector(".embla__button--prev");
  const nextBtn = htmlBanner5Node.querySelector(".embla__button--next");

  const setAutoplay = htmlBanner5Node.getAttribute("data-setAutoplay");
  const setSpeed = htmlBanner5Node.getAttribute("data-setSpeed");
  const setLoop = htmlBanner5Node.getAttribute("data-setLoop");
  const setNavigation = htmlBanner5Node.getAttribute("data-setNavigation");

  const AUTOPLAY =
    setAutoplay === "true"
      ? Autoplay({
          playOnInit: setAutoplay === "true",
          delay: Number(setSpeed),
        })
      : null;

  const OPTIONS = {
    dragFree: true,
    align: "start",
    loop: setLoop === "true",
  };

  const emblaApi = EmblaCarousel(
    viewportNode,
    OPTIONS,
    setAutoplay === "true" && [AUTOPLAY]
  );

  if (setNavigation === "true") {
    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      emblaApi,
      prevBtn,
      nextBtn
    );

    emblaApi.on("destroy", removePrevNextBtnsClickHandlers);
  }
}

const optionRowTitle = document.querySelector(".option-row h4");

if (optionRowTitle) {
  optionRowTitle.removeEventListener("click", () => {});
}

const categorySubMenuToggler = document.querySelectorAll(
  ".category-sub-menu .navbar-toggler"
);

if (categorySubMenuToggler.length > 0) {
  categorySubMenuToggler.forEach((toggler) => {
    const target = toggler.getAttribute("data-target");
    const categorySubMenu = document.querySelector(target);
    toggler.addEventListener("click", () => {
      categorySubMenu.classList.toggle("show");
      toggler.classList.toggle("open");
    });
  });
}

prestashop.on("clickQuickView", (elm) => {
  const data = {
    action: "quickview",
    id_product: elm.dataset.idProduct,
    id_product_attribute: elm.dataset.idProductAttribute,
  };
  $.post(prestashop.urls.pages.product, data, null, "json")
    .then((resp) => {
      $("body").append(resp.quickview_html);
      const productModal = $(
        `#quickview-modal-${resp.product.id}-${resp.product.id_product_attribute}`
      );
      productModal.modal("show");
      productConfig(productModal);
      productModal.on("hidden.bs.modal", () => {
        productModal.remove();
      });
    })
    .fail((resp) => {
      prestashop.emit("handleError", {
        eventType: "clickQuickView",
        resp,
      });
    });
});

const productConfig = (qv) => {
  const MAX_THUMBS = 4;
  const $arrows = $(prestashop.themeSelectors.product.arrows);
  const $thumbnails = qv.find(".js-qv-product-images");
  $(prestashop.themeSelectors.product.thumb).on("click", (event) => {
    // Swap active classes on thumbnail
    if ($(prestashop.themeSelectors.product.thumb).hasClass("selected")) {
      $(prestashop.themeSelectors.product.thumb).removeClass("selected");
    }
    $(event.currentTarget).addClass("selected");

    // Get data from thumbnail and update cover src, alt and title
    $(prestashop.themeSelectors.product.cover).attr(
      "src",
      $(event.target).data("image-large-src")
    );
    $(prestashop.themeSelectors.product.cover).attr(
      "alt",
      $(event.target).attr("alt")
    );
    $(prestashop.themeSelectors.product.cover).attr(
      "title",
      $(event.target).attr("title")
    );

    // Get data from thumbnail and update cover sources
    updateSources(
      $(prestashop.themeSelectors.product.cover),
      $(event.target).data("image-large-sources")
    );
  });
  if ($thumbnails.find("li").length <= MAX_THUMBS) {
    $arrows.hide();
  } else {
    $arrows.on("click", (event) => {
      if (
        $(event.target).hasClass("arrow-up") &&
        $(".js-qv-product-images").position().top < 0
      ) {
        move("up");
        $(prestashop.themeSelectors.arrowDown).css("opacity", "1");
      } else if (
        $(event.target).hasClass("arrow-down") &&
        $thumbnails.position().top + $thumbnails.height() >
          $(".js-qv-mask").height()
      ) {
        move("down");
        $(prestashop.themeSelectors.arrowUp).css("opacity", "1");
      }
    });
  }
  qv.find(prestashop.selectors.quantityWanted).TouchSpin({
    verticalbuttons: true,
    verticalupclass: "material-icons touchspin-up",
    verticaldownclass: "material-icons touchspin-down",
    buttondown_class: "btn btn-touchspin js-touchspin",
    buttonup_class: "btn btn-touchspin js-touchspin",
    min: 1,
    max: 1000000,
  });

  $(prestashop.themeSelectors.touchspin).off("touchstart.touchspin");
};

if (viewportNodeMainCarousel) {
  const prevBtnNode = viewportNodeMainCarousel.querySelector(
    ".embla__button--prev"
  );
  const nextBtnNode = viewportNodeMainCarousel.querySelector(
    ".embla__button--next"
  );
}

const OPTIONS = { align: "start" };
const OPTIONS_THUMBS = {
  containScroll: "keepSnaps",
  dragFree: true,
};

const viewportNodeQuickView = document.querySelector(
  ".quickview .embla__viewport"
);
const viewportNodeQuickViewThumb = document.querySelector(
  ".quickview .embla-thumbs__viewport"
);

if (viewportNodeQuickView && viewportNodeQuickViewThumb) {
  const emblaApiMain = EmblaCarousel(viewportNodeQuickView, OPTIONS);
  const emblaApiThumb = EmblaCarousel(
    viewportNodeQuickViewThumb,
    OPTIONS_THUMBS
  );

  const removeThumbBtnsClickHandlers = addThumbBtnsClickHandlers(
    emblaApiMain,
    emblaApiThumb
  );
  const removeToggleThumbBtnsActive = addToggleThumbBtnsActive(
    emblaApiMain,
    emblaApiThumb
  );
  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApiMain,
    prevBtnNode,
    nextBtnNode
  );

  emblaApiMain
    .on("destroy", removeThumbBtnsClickHandlers)
    .on("destroy", removePrevNextBtnsClickHandlers);

  emblaApiThumb
    .on("destroy", removeThumbBtnsClickHandlers)
    .on("destroy", removeToggleThumbBtnsActive);
}

const handleRemoveOpened = (node, nodeList) => {
  const parent = node.parentElement;
  const dataDepth = parent.getAttribute("data-depth");

  nodeList.forEach((item) => {
    if (item.parentElement.getAttribute("data-depth") === dataDepth) {
      item.querySelector(".dropdown-item.with-ul")?.classList.remove("show");
      item.querySelector(".arrowDecoration")?.classList.remove("open");
      item.querySelector(".submenu")?.classList.remove("show");
    }
  });
};

const mobileDropdown = document.querySelectorAll(
  "#top-menu .category, #top-menu .manufacturers"
);
const windowWidth = window.innerWidth;

if (mobileDropdown.length > 0) {
  mobileDropdown.forEach((dropdown) => {
    const link = dropdown.querySelector(".dropdown-item.with-ul");
    if (link) {
      const arrowDecoration = dropdown.querySelector(".arrowDecoration");
      const subMenu = dropdown.querySelector(".submenu");
      if (subMenu) {
        subMenu.style.transform = "translate(0)";
        if (windowWidth < 991) {
          subMenu.style.maxWidth = "100%";
          subMenu.style.backgroundColor = "transparent";
          subMenu.style.borderRadius = "0";
        }
      }
      arrowDecoration.addEventListener("click", (e) => {
        if (e.target === arrowDecoration) {
          e.preventDefault();
          e.stopPropagation();
          if (!arrowDecoration.classList.contains("open")) {
            handleRemoveOpened(dropdown, mobileDropdown);
          }
          link.classList.toggle("show");
          arrowDecoration.classList.toggle("open");
          const mainMenuWrapper = document.querySelector(".main-menu--wrapper");
          mainMenuWrapper.classList.add("show");
          subMenu.classList.toggle("show");
        }
      });
    }
  });
}

const lightboxContainer = document.querySelector(".lightbox-container");

if (lightboxContainer) {
  Lightbox.init(lightboxContainer);
}

const checkOutPage = document.querySelector(
  "#checkout-personal-information-step"
);

if (checkOutPage) {
  const checkoutGuestForm = checkOutPage.querySelector(
    "a[href='#checkout-guest-form']"
  );
  const checkoutLoginForm = checkOutPage.querySelector(
    "a[href='#checkout-login-form']"
  );
  const checkoutGuestFormTabContent = checkOutPage.querySelector(
    "#checkout-guest-form"
  );
  const checkoutLoginFormTabContent = checkOutPage.querySelector(
    "#checkout-login-form"
  );
  if (checkoutGuestForm) {
    checkoutGuestForm.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      checkoutGuestForm.classList.add("active");
      checkoutLoginForm.classList.remove("active");
      checkoutGuestFormTabContent.classList.add("active");
      checkoutLoginFormTabContent.classList.remove("active");
    });
  }

  if (checkoutLoginForm) {
    checkoutLoginForm.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      checkoutGuestForm.classList.remove("active");
      checkoutLoginForm.classList.add("active");
      checkoutGuestFormTabContent.classList.remove("active");
      checkoutLoginFormTabContent.classList.add("active");
    });
  }
}

const collapseButton = document.querySelectorAll(".collapse-button");
const jsShowDetails = document.querySelector(".js-show-details");

if (jsShowDetails) {
  const target = jsShowDetails.getAttribute("data-target");
  const collapse = document.querySelector(target);
  if (collapse) {
    jsShowDetails.addEventListener("click", () => {
      collapse.classList.toggle("collapse");
    });
  }
}

if (collapseButton.length > 0) {
  collapseButton.forEach((button) => {
    const target = button.getAttribute("data-target");
    const collapse = document.querySelector(target);
    if (collapse) {
      button.addEventListener("click", () => {
        collapse.classList.toggle("collapse");
      });
    }
  });
}

const collapseDiv = document.querySelectorAll(".navbar-toggler.collapse-icons");

if (collapseDiv.length > 0) {
  collapseDiv.forEach((button) => {
    const target = button.getAttribute("data-target");
    const collapse = document.querySelector(target);
    if (collapse && target) {
      const collapseAttribute = collapse.getAttribute("aria-expanded");

      if (!collapseAttribute) {
        collapse.setAttribute("aria-expanded", "false");
      }
    }

    if (collapse) {
      button.addEventListener("click", () => {
        collapse.setAttribute(
          "aria-expanded",
          collapse.getAttribute("aria-expanded") === "true" ? "false" : "true"
        );
        collapse.classList.toggle("show");
      });
    }
  });
}

const languageSelector = document.querySelector(".language-selector");

if (languageSelector) {
  const dropdownMenu = languageSelector.querySelector(".dropdown-menu");
  if (dropdownMenu) {
    languageSelector.addEventListener("click", () => {
      dropdownMenu.classList.toggle("show");
    });
  }
}

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (
        node.classList &&
        node.classList.contains("quickview") &&
        node.classList.contains("show")
      ) {
        const quickviewContainer = node.querySelector(".embla");
        handleQuickViewEmbla(quickviewContainer);
      }
    });
  });
});

observer.observe(document.body, { subtree: false, childList: true });

const blockCartModalObserver = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node.id && node.id === "blockcart-modal") {
        const blockCartModal = node;
        const blackCartModalBackdrop =
          document.querySelector(".modal-backdrop");
        const blockCartClose = blockCartModal.querySelector(".close");
        const header = document.querySelector("#header");
        const blockCartContinueShopping =
          blockCartModal.querySelector(".btn.btn-secondary");
        if (blockCartClose) {
          blockCartClose.addEventListener("click", () => {
            blockCartModal.classList.remove("show");
            blackCartModalBackdrop.classList.remove("show");
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
            header.style.paddingRight = "0";
            document.body.removeChild(blockCartModal);
            document.body.removeChild(blackCartModalBackdrop);
          });
        }
        if (blockCartContinueShopping) {
          blockCartContinueShopping.addEventListener("click", () => {
            blockCartModal.classList.remove("show");
            blackCartModalBackdrop.classList.remove("show");
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
            header.style.paddingRight = "0";
            document.body.removeChild(blockCartModal);
            document.body.removeChild(blackCartModalBackdrop);
          });
        }
      }
    });
  });
});

blockCartModalObserver.observe(document.body, {
  subtree: false,
  childList: true,
});

const buttonTop = document.querySelector(".btn-to-top");
if (buttonTop) {
  buttonTop.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
}

const featuredProducts = document.querySelectorAll(".featured-products");

if (featuredProducts.length > 0) {
  featuredProducts.forEach((element) => {
    const title = element.querySelector(".h6.text-uppercase");
    const toggleBlock = element.querySelector(".toggle-block");
    if (title && toggleBlock) {
      title.addEventListener("click", () => {
        toggleBlock.classList.toggle("active");
      });
    }
  });
}
